.account-request-form {
    position: relative;
    .spinner-container {
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: #ffffffbf;
    }
    h2 {
        margin: 0;
        font-weight: 400;
        margin-bottom: 1rem;
    }
    .form-item-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 1rem;
        row-gap: 1rem;
        margin-bottom: 1.5rem;
    }
    .form-item {
        label {
            display: block;
            width: 100%;
            font-weight: 700;
            font-size: 0.75rem;
            margin-bottom: 0.5rem;
        }
        select {
            height: 36px;
            font-family: 'Inter';
        }
        input, select {
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding: 0.5rem;
            outline: none;
        }
        textarea {
            width: 100%;
            min-height: 200px;
            box-sizing: border-box;
            padding: 0.5rem;
            outline: none;
        }
    }
   
        .submit-button {
            border-radius: 96px;
            font-weight: 700;
           border: 1px solid #0067b1;
           background-color: #0067b1;
           color: #FFF;
           cursor: pointer;
            padding: 0;
            vertical-align: top;
            line-height: 36px;
            margin-right: 1rem;
            box-sizing: border-box;
            text-transform: uppercase;
            padding: 0 1rem;
            &.icon {
                padding-left: calc(24px + 0.7rem);
                position: relative;
                svg {
                    position: absolute;
                    left: 0.5rem;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
            &:last-child {
                margin-right: 0;
            }
            
        }
    
}