body {
    font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
}

.main-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

.header-wrapper {
  height: 60px;
  background-color: #333;
}

.main-view {
  overflow: hidden;
}

.body-wrapper {
  position: absolute;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
}

//clear table formatting
table {
  border-collapse: separate;
    width: 100%; // Adjust as needed
    border-spacing: 0;

    th, td {
      border: none;
      padding: 0.5rem; // Adjust padding as needed, or set to 0 for no padding
      text-align: left; // Adjust text alignment as needed
  
      // Optionally, reset font styles
      font-size: 0.9rem; // Adjust font size as needed
      font-weight: normal;
    }
  
    // Remove spacing and borders
    caption, th, td {
      border-style: none;
    }
  
    // Reset table header styling
    thead {
      th {
        background-color: transparent; // Remove any background color
        color: inherit; // Use the inherited font color
        
      }
    }
  
    // Reset table body styling
    tbody {
      tr {
        &:nth-child(odd) {
          background-color: transparent; // Remove striped rows, if any
        }
      }
  
      td {
        // Additional cell styling reset, if needed
      }
    }
  
    // Reset table footer styling
    tfoot {
      td, th {
        background-color: transparent; // Remove any background color
        color: inherit; // Use the inherited font color
      }
    }
  }
  
  button {
    height: 36px;
    box-sizing: border-box;
    font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  font-variation-settings:
    "slnt" 0;
    font-size: 0.8rem;
  }

  input, textarea {
    font-family: "Inter", sans-serif;
  }

  :root {
    --sb-track-color: #dcdcdc;
    --sb-thumb-color: #a0a0a0;
    --sb-size: 7px;
  }
  
  /* Apply scrollbar styles globally */
  *::-webkit-scrollbar {
    width: var(--sb-size);
    height: var(--sb-size); /* For horizontal scrollbars */
  }
  
  *::-webkit-scrollbar-track {
    background: var(--sb-track-color);
    border-radius: 3px;
  }
  
  *::-webkit-scrollbar-thumb {
    background: var(--sb-thumb-color);
    border-radius: 3px;
  }
  
  @supports not selector(::-webkit-scrollbar) {
    html {
      scrollbar-color: var(--sb-thumb-color) var(--sb-track-color);
      scrollbar-width: thin; /* or `auto` or `thin` based on preference */
    }
  }
  