@import '../../../global';

.account-request-loading {
    width: 100%;
    height: 100%;
    background-color: #f6f6f6;
}

.fade-in {
    opacity: 0; /* Start with an opacity of 0 */
    animation: fadeInAnimation 0.25s forwards; /* Define the animation */
  }
  
  @keyframes fadeInAnimation {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  
.account-request-details {
    width: 100%;
    box-sizing: border-box;
    //padding: 1rem;
    .details-header {
        background-color: #0067b1;
        padding: 1rem;
        position: sticky;
        top: 0;
        box-sizing: border-box;
        z-index: 10;
       // height: calc(1rem + 38px + 1rem);
        h2 {
            font-weight: 400;
            margin: 0;
            color: #fff;
        }
        .date-created {
            font-size: 0.8rem;
            color: #c8e2ff;
            text-transform: uppercase;
        }
        [class^="tag-"] {
            padding: 0.3rem 1rem;
            margin: 0.5rem 0;
        }
        .close-button {
            position: absolute;
            top: 1rem;
            right: 1rem;
            background-color: transparent;
            border: 0;
            cursor: pointer;
            svg {
                color: #fff;
                
            }
        }
    }

    h3 {
        font-weight: 400;
        margin: 0;
        margin-bottom: 0.5rem;
        margin-top: 1rem;
    }
    .details-body {
        background-color:#f6f6f6;
        padding: 1rem;
    }
    
    .copy {
        background-color: transparent;
        border: 0;
        cursor: pointer;
        height: 24px;
    }

    dl, .detail-section {
        border: 1px solid #00070d4d;
        border-radius: 10px;
        background-color: #fff;
        padding: 0.5rem 0;
        margin: 0;
        .item {
            //background-color: #fff;
            display: block;
            padding: 0.5rem 1rem;
           .copy {
                opacity: 0;
              transition: opacity 0.25s;
              display: inline-block;
              position: absolute;
              pointer-events: none;
           }
           &:hover {
            .copy {
                opacity: 1;
                pointer-events: all;
            }
           }
        }
        dt {
            color: #777;
            font-size: 0.75rem;
        }
        dd {
            word-break: break-all;
            margin: 0;
            font-size: 0.85rem;
            line-height: 1.4rem;
            position: relative;
        }
    }

    .history {
        padding: 0.5rem;
        &.detail-section {
            padding: 0;
            overflow: hidden;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style-type: none;
            font-size: 0.75rem;
            li {
                margin: 0;
                padding: 1rem 1rem;
                &:nth-child(odd) {
                    background-color: #f9f9f9; // Light gray for odd rows
                  }
                
                &:nth-child(even) {
                    background-color: #e9e9e9; // Slightly darker gray for even rows
                  }
            }
        }
        .history-list-item {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, auto);
            grid-column-gap: 0.25rem;
            grid-row-gap: 0.25rem;
            align-items: start;
            .date {
                display: block;
                grid-area: 1 / 1 / 2 / 2;
                font-weight: 400;
                color: #777;
            }
            .event {
                text-align: right;
                grid-area: 1 / 2 / 2 / 3;
                text-transform: uppercase;
                font-weight: 400;
                color: #777;
            }
            .detail {
                grid-area: 2 / 1 / 3 / 3;
                font-size: 0.85rem;
            }
        }
    }
}