@import '../../../global';

.data-table-wrapper {
    .infinite-scroll-component {
        overflow: inherit !important;
        height: inherit !important;
    }
}

.table-loader {
    padding: 2rem;
}

.data-table {
    table-layout: fixed;
    width: 100%;


    th {
        text-align: center;
    }
    td, th {
        height: 20px;
        overflow: hidden;
 text-overflow: ellipsis;
 white-space: nowrap;

        text-align: center;
        font-size: 0.7rem;
        @include media('>medium') {
            font-size: 0.75rem;
        }
    &:first-child {
        text-align: left;
        padding-left: 1rem;
    }
    &:last-child {
        padding-right: 1rem;
    }
    }
    
    thead {
                
        th {
            
            background-color: #fff; // Light gray background for the table header
            color: rgb(0, 103, 177); // Dark text color for contrast
            font-weight: 700;
            text-transform: uppercase;
            position: sticky;
            top: calc(1rem + 36px + 1rem);
            background-color: #f9f9f9; /* So that header is not transparent */
            z-index: 2; /* Ensures the header is above other content */
            border-bottom: 1px solid #00070d4d;
            box-sizing: border-box;
        }
    }

    th:nth-child(1), td:nth-child(1) { width: 10%; } /* First column width */
    th:nth-child(2), td:nth-child(2) { width: 20%; } /* Second column width */
    th:nth-child(3), td:nth-child(3) { width: 20%; } /* Second column width */
    th:nth-child(4), td:nth-child(4) { width: 15%; } /* Second column width */
    th:nth-child(5), td:nth-child(5) { width: 14%; } /* Second column width */
    th:nth-child(6), td:nth-child(6) { width: 12%; } /* Second column width */
    th:nth-child(7), td:nth-child(7) { width: 12%; } /* Second column width */
    th:nth-child(8), td:nth-child(8) { width: 12%; } /* Second column width */
    
     // Alternating row colors
  tbody tr:nth-child(odd) {
    background-color: #f9f9f9; // Light gray for odd rows
  }

  tbody tr:nth-child(even) {
    background-color: #e9e9e9; // Slightly darker gray for even rows
  }

  tbody tr {
    cursor: pointer;
    &.selected {
        background-color: #d9efff;
    }
}
}
