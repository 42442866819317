.search-bar {
    width: 100%;
    position: relative;
    .search-icon {
        position: absolute;
        top: 50%;
        left: 0.7rem;
        transform: translateY(-50%);
        pointer-events: none;
    }
    input {
        font-family: "Inter", sans-serif;
        padding-left: 2.6rem;
        font-weight: 700;
        border-radius: 96px;
        border: 1px solid #ccc;
        background-color: #fafafa;
        box-sizing: border-box;
        width: 100%;
        height: 36px;
        &:focus {
            outline: none;
        }
    }
    
}