.spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    width: 28px;
    height: 28px;
    border-radius: 50%;
    border-left-color: #0067b1;
  
    animation: spin 1s ease infinite;
  }
  
  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Adjust the height as needed */
  }

  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  