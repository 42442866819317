.logo {
    max-height: 40px;
    margin-top: 10px;
    margin-left: 1rem;
    opacity: 1;
    mix-blend-mode: luminosity;
}
.logout {
    position: absolute;
    right : 1rem;
    background-color: transparent;
    border: 0px;
    color: #f4f4f4;
    opacity: 0.5;
    line-height: 60px;
    height: 60px;
    box-sizing: border-box;
    text-transform: uppercase;
}