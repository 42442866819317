@import '../node_modules/include-media/dist/include-media';

// Override the default breakpoints map with your custom breakpoints
$breakpoints: (
  small: 320px,
  medium: 768px,
  large: 1024px,
);

table {
  [class^="tag-"] {
    width: 90%;
  }
}

.tag-pending {
  background-color: #dcdcdc;
  color: #333;
  display: inline-block;
  padding: 0.3rem;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 600;
}
.tag-sent {
  background-color: rgb(132, 255, 132);
  color: #1b513f;
  display: inline-block;
  padding: 0.3rem;
  border-radius: 25px;
  text-transform: uppercase;
  font-size: 0.65rem;
  font-weight: 600;
}

.icon-button {
  border-radius: 96px;
  font-weight: 700;
 border: 1px solid #0067b1;
 background-color: #0067b1;
 color: #FFF;
 cursor: pointer;
  padding: 0;
  vertical-align: top;
  line-height: 36px;
  margin-right: 1rem;
  box-sizing: border-box;
  text-transform: uppercase;
  padding: 0 1rem;
  &.icon {
      padding-left: calc(24px + 0.7rem);
      position: relative;
      svg {
          position: absolute;
          left: 0.5rem;
          top: 50%;
          transform: translateY(-50%);
      }
  }
  
  
}

.align-center {
  text-align: center;
}