.account-requests-layout {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 400px;
    position: relative;
    .main-view {
        height: 100%;
        overflow-y: scroll;
    }
    .detail-view {
        height: 100%;
        overflow: auto;
        background-color: #f6f6f6;
    }

    .account-requests-options {
        padding: 1rem;
        position: sticky;
        top: 0;
        z-index: 4;
        height: calc(1rem + 36px + 1rem);
        background-color: #fff;
        display: grid;
        grid-template-columns: 1fr auto;
        column-gap: 1rem;
        box-sizing: border-box;
        .button-area {
            button {
                border-radius: 96px;
                font-weight: 700;
               border: 1px solid #0067b1;
               background-color: #0067b1;
               color: #FFF;
               cursor: pointer;
                padding: 0;
                vertical-align: top;
                line-height: 36px;
                margin-right: 1rem;
                box-sizing: border-box;
                text-transform: uppercase;
                padding: 0 1rem;
                &.icon {
                    padding-left: calc(24px + 0.7rem);
                    position: relative;
                    svg {
                        position: absolute;
                        left: 0.5rem;
                        top: 50%;
                        transform: translateY(-50%);
                    }
                }
                &:last-child {
                    margin-right: 0;
                }
                
            }
        }
    }
}
